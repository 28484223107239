<template>
  <div>
    <div v-if="! isModal" class="level is-variable bd-klmn-columns is-1 is-marginless">
      <div class="level-left">
        <b-tooltip :label="$t('clearFilter')" type="is-dark" position="is-right">
          <b-button
            class="filter-button is-hidden-mobile"
            size="is-circle"
            icon-left="filter-remove"
            @click="clearFilter"
          />
        </b-tooltip>
        <div class="filter-tags">
          <b-tag
            v-for="(filter, index) in filters"
            :key="index"
            type="is-purble"
            closable
            @close="removeFilter(filter)"
          >
            {{ $t('filter.' + filter) }}
          </b-tag>
        </div>
      </div>
      <div class="level-right">
        <slot name="header-right" />
      </div>
    </div>
    <b-table
      :data="data"
      :loading="isLoading"

      :current-page.sync="page"
      :total="total"
      :per-page="perPage"

      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      striped
      sticky-header
      paginated
      hoverable
      backend-sorting
      backend-pagination

      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"

      class="no-wrap"
      @page-change="onPageChange"
      @sort="onSort"
    >
      <b-table-column v-slot="props" custom-key="actions" class="is-actions-cell" sticky>
        <b-tooltip :label="$t('copy')" type="is-dark" position="is-right">
          <b-button
            outlined
            size="is-small"
            class="button-green is-info mr-5"
            icon-left="content-copy"
            tag="router-link"
            :to="{ name: 'transfers.create', params: { id: props.row.id }}"
          />
        </b-tooltip>
        <b-button
          outlined
          size="is-small"
          class="button-purble btn-details"
          icon-left="arrow-right-bold"
          tag="a" :href="transferURL(props.row.id)"
          :target="transferTarget"
          @click.prevent="showTransfer(props.row.id)"
        >
          {{ $t('details') }}
        </b-button>
      </b-table-column>

      <b-table-column :label="$t('filter.id')" field="id" number sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="id-search search-dropdown" :close-on-click="false" trap-focus>
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item" :focusable="false">
              <b-input v-model="queryData.id" :placeholder="$t('idSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          #{{ props.row.id }}
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.name')">
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item" :focusable="false">
              <b-input v-model="queryData.name" :placeholder="$t('nameSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <b-tooltip
            :label="props.row.name"
            :active="props.row.name.length > 20"
            type="is-dark"
            position="is-bottom"
          >
            {{ props.row.name | limitShow }}
          </b-tooltip>
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.dateRange')" field="transfer_date" sortable date centered>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="date-search search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item custom paddingless aria-role="menu-item" :focusable="false">
              <b-datepicker
                v-model="queryData.dateRange"
                :placeholder="$t('dateSearch')"
                :first-day-of-week="1"
                :month-names="$t('dateTimePicker.monthNames')"
                :day-names="$t('dateTimePicker.dayNames')"
                icon="calendar-today"
                range
                inline
              >
                <div class="button is-danger" @click="queryData.dateRange = []">
                  {{ $t('clear') }}
                </div>
              </b-datepicker>
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <small class="tag is-abbr-like" :title="props.row.transfer_date">
            {{ props.row.transfer_date | formatDate }}
          </small>
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.departure')" field="start_address" sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="list" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item">
              <b-input v-model="queryData.departure" :placeholder="$t('departureSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <b-tooltip :label="props.row.start_address" type="is-dark" position="is-bottom">
            {{ props.row.start_address | formatAddress }}
          </b-tooltip>
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.target')" field="target_address" sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="list" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item">
              <b-input v-model="queryData.target" :placeholder="$t('targetSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <b-tooltip :label="props.row.target_address" type="is-dark" position="is-bottom">
            {{ props.row.target_address | formatAddress }}
          </b-tooltip>
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.status')" field="status" sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown v-model="queryData.status" multiple aria-role="list" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item
              v-for="(status, index) in transferStatus"
              :key="index"
              :value="index"
              aria-role="listitem"
            >
              <span>{{ status }}</span>
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <span
            class="tag"
            :class="[{ 'has-suggestion-mark': props.row.suggestion}, $options.filters.showStatus(props.row.status)]"
          >
            {{ $t(`status.${props.row.status}`) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column :label="$t('type')" field="self_drive" sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown v-model="queryData.self_drive" aria-role="list" :close-on-click="true">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item
              value="1"
              aria-role="listitem"
            >
              <span>{{ $t('selfDrive.true') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              value="0"
              aria-role="listitem"
            >
              <span>{{ $t('selfDrive.false') }}</span>
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <small :class="`tag is-abbr-like self-column-${props.row.self_drive}`">
            {{ $t(`selfDrive.${props.row.self_drive}`) }}
          </small>
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.driver')">
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item" :focusable="false">
              <b-input v-model="queryData.driver" :placeholder="$t('driverSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <span v-if="props.row.driver">
            {{ props.row.driver | limitShow }}
          </span>
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.plate')" field="car_plate">
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="list" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item">
              <b-input v-model="queryData.plate" :placeholder="$t('plateSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <small v-if="props.row.car_plate" class="tag is-abbr-like">
            {{ props.row.car_plate }}
          </small>
        </template>
      </b-table-column>

      <b-table-column label="Yolcu Puan" centered>
        <template v-slot="props">
          <small v-if="props.row.reviews.length">
            {{ passengerRating(props.row.reviews) }}
          </small>
        </template>
      </b-table-column>

      <b-table-column label="Sürücü Puan" centered>
        <template v-slot="props">
          <small v-if="props.row.reviews.length">
            {{ driverRating(props.row.reviews) }}
          </small>
        </template>
      </b-table-column>

      <b-table-column label="Başlangıç KM">
        <template v-slot="props">
          <small v-if="props.row.start_km" class="tag is-abbr-like">
            {{ props.row.start_km }}
          </small>
        </template>
      </b-table-column>

      <b-table-column label="Bitiş KM">
        <template v-slot="props">
          <small v-if="props.row.end_km" class="tag is-abbr-like">
            {{ props.row.end_km }}
          </small>
        </template>
      </b-table-column>

      <b-table-column label="Yapılan KM">
        <template v-slot="props">
          <small v-if="isNumber(props.row.end_km) && isNumber(props.row.start_km)" class="tag is-abbr-like">
            {{ km(props.row.end_km, props.row.start_km) }}
          </small>
        </template>
      </b-table-column>

      <b-table-column v-slot="props" :label="$t('transferDuration')" field="road_time" sortable>
        <small v-if="props.row.road_time" class="tag is-abbr-like">
          {{ props.row.road_time }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" :label="$t('transferDistance')" field="road_distance" sortable>
        <small v-if="props.row.road_distance" class="tag is-abbr-like">
          {{ props.row.road_distance }}
        </small>
      </b-table-column>

      <template slot="bottom-left">
        <div v-if="total > 0" class="has-text-left subtitle help">
          {{ $t('paginationShowing', [total.toLocaleString(), ((page * perPage) - perPage) + 1, ((page * perPage) > total ? total : (page * perPage)) ]) }}
        </div>
      </template>

      <b-table-column :label="$t('filter.user_department')" centered>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item" :focusable="false">
              <b-input v-model="queryData.user_department" :placeholder="$t('search')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <b-tooltip
            :label="props.row.user_department"
            :active="props.row.user_department && props.row.user_department.length > 20"
            type="is-dark"
            position="is-bottom"
          >
            {{ props.row.user_department | limitShow }}
          </b-tooltip>
        </template>
      </b-table-column>

      <b-table-column label="Açıklama" centered>
        <template v-slot="props">
          <b-tooltip
            :label="props.row.user_comment"
            :active="props.row.user_comment && props.row.user_comment.length > 20"
            type="is-dark"
            position="is-bottom"
          >
            {{ props.row.user_comment | limitShow }}
          </b-tooltip>
        </template>
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>{{ $t('fetching') }}...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="table" size="is-large" />
            </p>
            <p>{{ $t('nodata') }}</p>
          </template>
        </div>
      </section>
    </b-table>

    <b-modal
      :active.sync="isTransferModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      animation=""
      aria-modal
    >
      <div class="modal-card full-width" style="width: auto">
        <section class="modal-card-body full-width">
          <transfer-details
            :id="transferId"
            :isModal="true"
            :driverId="driverId"
            :carId="carId"
          />
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import debounce from 'lodash/debounce'
import download from 'downloadjs'
import $ from 'jquery'

export default {
  name: 'TransfersTable',
  components: {
    TransferDetails: () => import(/* webpackPrefetch: true */ '@/views/TransferDetails'),
  },
  props: {
    dataUrl: {
      type: String,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    },
    where: {
      type: Object,
      default: () => {}
    },
    driverId: {
      type: Number,
      default: null
    },
    carId: {
      type: Number,
      default: null
    }
  },
  data () {
    const transferStatus = this.$i18n.t('status');

    return {
      transferStatus: transferStatus,
      isTransferModalActive: false,
      transferId: null,
      trashObject: null,
      data: [],
      isLoading: false,
      perPage: 50,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      total: 0,
      page: 1,
      count: 0,
      inputTrigger: null,
      queryData: {
        id: '',
        name: '',
        departure: '',
        target: '',
        driver: '',
        plate: '',
        dateRange: [],
        status: [],
        self_drive: '',
        user_department: '',
        excel: 0,
      },
      filters: [],
    }
  },
  computed: {
    transferTarget () {
      if (this.isModal) {
        return '_blank';
      } else {
        return '_self'
      }
    },
    ...mapState([
      'boardRefresh',
    ]),
  },
  watch: {
    query () {
      this.filterAction()
    },
    queryData: {
      handler () {
        this.filterAction()
      },
      deep: true
    },
    'queryData.dateRange' () {
      setTimeout(function () {
        $('body').trigger('click');
      }, 250);
    },
    'queryData.id' (n) {
      if (n.indexOf('#') > -1) {
        this.queryData.id = n.replace('#', '');
      }
    },
    boardRefresh (n) {
      if (n == true) {
        this.loadTransfers()
      }
    }
  },
  mounted () {
    if (this.where) {
      this.queryData = this.$_.merge(this.queryData, this.where);
    }
    this.isLoading = true
    this.loadTransfers();
  },
  methods: {
    passengerRating (reviews) {
      return reviews.find(({ author }) => author.role !== 'Driver')?.rating
    },
    driverRating (reviews) {
      return reviews.find(({ author }) => author.role === 'Driver')?.rating
    },
    filterAction: debounce(function () {
      this.filterShow()
      this.loadTransfers();
    }, 350),

    clearFilter () {
      this.queryData = {
        id: '',
        name: '',
        departure: '',
        target: '',
        driver: '',
        plate: '',
        self_drive: '',
        dateRange: [],
        status: [],
        user_department: '',
        excel: 0,
      }
      this.filterShow()
      // this.loadTransfers();
    },
    loadTransfers () {
      const params = [
        `sort_by=${this.sortField}`,
        `sort_order=${this.sortOrder}`,
        `page=${this.page}`,
        `status=${this.queryData.status}`,
        `id=${this.queryData.id}`,
        `name=${this.queryData.name}`,
        `departure=${this.queryData.departure}`,
        `target=${this.queryData.target}`,
        `driver=${this.queryData.driver}`,
        `self_drive=${this.queryData.self_drive}`,
        `plate=${this.queryData.plate}`,
        `user_department=${this.queryData.user_department}`,
        `excel=${this.queryData.excel}`,
        `date=${[
          (this.queryData.dateRange[0] ? [moment(this.queryData.dateRange[0]).format('YYYY-MM-DD')] : [null]),
          (this.queryData.dateRange[1] ? [moment(this.queryData.dateRange[1]).format('YYYY-MM-DD')] : [null])
        ]}`,
      ].join('&');

      if (this.dataUrl) {
        this.$emit('loadingChange', true)
        let responseType = 'json'

        if (this.queryData.excel) {
          responseType = 'blob'
        }

        this.$http({
          url: `${this.dataUrl}?${params}`,
          method: 'GET',
          responseType: responseType,
        })
          .then(response => {
            if (this.queryData.excel) {
              const mimType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              const fileName = `transfers-${moment().format('DD-MM-YYYY-h-mm')}.xlsx`
              download(response.data, fileName, mimType)
              this.isLoading = false
              this.$emit('excelChange', false)
              this.$emit('loadingChange', false)
              this.queryData.excel = 0
              return false;
            }

            this.data = []
            this.total = 0
            const data = response.data

            if (data && data.data) {
              window.scrollTo(0,0);

              if (data.meta) {
                this.total = data.meta.total
                this.perPage = data.meta.per_page
                this.page = data.meta.current_page
                this.count = data.meta.to
              } else {
                this.total = 0
              }
              this.data = data.data
            }
          })
          .catch(e => {
            this.data = []
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger'
            })
          })
          .then(() => {
            this.$nextTick(() => {
              this.isLoading = false
              this.$emit('loadingChange', false)
              this.$emit('excelChange', false)

              this.$store.commit('boardRefresh', false)
              this.jqInputTrigger()
            })
          })
      }
    },
    jqInputTrigger () {
      if (this.inputTrigger) return;

      $('.dropdown-trigger').click(function (e) {
        e.stopPropagation()
        $(this).parent().find('input, select').first().focus()
      })
      $('.search-dropdown input').keypress(function (e) {
        if (e.which == '13') {
          $('body').trigger('click')
        }
      });
      $('.dropdown').click(function (e) {
        e.stopPropagation()
      })
      this.inputTrigger = true;
    },
    onPageChange (page) {
      this.page = page
      this.loadTransfers()
    },
    onSort (field, order) {
      this.page = 1
      this.sortField = field
      this.sortOrder = order
      this.loadTransfers()
    },
    type (value) {
      const number = parseFloat(value)
      if (number < 6) {
        return 'is-danger'
      } else if (number >= 6 && number < 8) {
        return 'is-warning'
      } else if (number >= 8) {
        return 'is-success'
      }
    },
    filterShow () {
      this.filters = this.$_.keys(this.$_.omitBy(this.queryData, this.$_.isEmpty))
    },
    removeFilter (filter) {
      this.queryData[filter] = this.$_.isArray(this.queryData[filter]) ? [] : '';
      this.filterShow()
    },
    exportExcel () {
      this.queryData.excel = 1;
    },
    transferURL (transferId) {
      let object = {
        name: 'transfers.show',
        params: { id: transferId },
        query: {}
      }
      if (this.carId) {
        object.query.car_id = this.carId
      }

      if (this.driverId) {
        object.query.driver_id = this.driverId
      }

      return this.$router.resolve(object).href
    },
    showTransfer (transferId) {
      this.transferId = transferId
      this.isTransferModalActive = true
    },
    isNumber (value) {
      return !isNaN(parseFloat(value)) && isFinite(value)
    },
    km (end, start) {
      return Math.abs(parseInt(end) - parseInt(start)) || null
    },
  },
}
</script>
